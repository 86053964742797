import { createRouter, createWebHistory } from 'vue-router'

const Home = () => import('../views/Home/index.vue')
const Empresa = () => import ('../views/Empresa/index.vue')
const Produtos = () => import('../views/Produtos/index.vue')
const Contato = () => import('../views/Contato/index.vue')
const Sustentabilidade = () => import('../views/Sustentabilidade/index.vue')



const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/empresa',
    name: 'Empresa',
    component: Empresa
  },
  {
    path: '/produtos',
    name: 'Produtos',
    component: Produtos
  },
  {
    path: '/contato',
    name: 'Contato',
    component: Contato
  },
  {
    path: '/sustentabilidade',
    name: 'Sustentabilidade',
    component: Sustentabilidade
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'Home' }
  }
 
]

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

export default router
