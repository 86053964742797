import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueSplide from '@splidejs/vue-splide'

import '@/assets/css/tailwind.css'
import '@/assets/css/fonts.css'

import '@splidejs/vue-splide/css'

import 'animate.css'

// or other themes
import '@splidejs/vue-splide/css/skyblue'
import '@splidejs/vue-splide/css/sea-green'

// or only core styles
import '@splidejs/vue-splide/css/core'


const app = createApp(App)
app.use(router)
app.mount('#app')
app.use( VueSplide )
